import React from "react";
import "./heroheader.css";
import Lars from "./avataaars.svg";
import Portfolio from "../Projects/Projects";

function HeroHeader() {
  let technologies = [
    "Adobe Illustrator",
    "Adobe After Effects",
    "Adobe Premiere Pro",
    "Adobe Audition",
    "Adobe Lightroom",
    "Articulate Storyline",
    "JavaScript",
    "HTML",
    "CSS",
    "React",
    "C#",
    "SQL",
    "GraphQL",
    "Python",
    "Git",
    "GitHub",
    "PHP"
  ];

  return (
    <div className="column">
      <div className="column-lg-12 row center">
        <div className="column column-md-6 column-lg-5">
          <img
            src={Lars}
            className="column-lg-12 hero-image"
            alt="Lars headshot"
          />
        </div>
        <div className="column column-lg-5 column-md-12 bio-container center billboard">
          <code>tl;dr</code>
          <h1>
            Lars Mackenzie is a{" "}
            <span className="highlight">user-centered designer</span>,{" "}
            <span className="highlight">developer</span>, and{" "}
            <span className="highlight">researcher</span> with expertise in
            learning.
          </h1>
          <nav><a href="#about">about</a><a href="#skills">skills</a><a href="#contact">contact</a><a href="/photography">photography</a></nav>
        </div>
      </div>
      <div className="row center">
        <div id="scrolly">
          <div className="column bio-container">
            <code>
              <h2 id="about">About</h2>
            </code>
            <p>
              Hi, I'm Lars! I am a creative developer and strategist who works at the intersection of learning and technology. In my current role, I lead teams that create learning experiences for busy people who want to get better at their jobs. I use a lot of different tools toward this end: video, motion graphics, sound, code, text, and simulation. Everything I do is research-informed and user-centered. 
            </p>
            <p>
             Since 2014, I have also worked as a freelance web developer and user
              experience designer. I build websites from scratch that
              integrate with content management systems; redesign
              user experiences; and help clients reach audiences, tell their stories,
              and measure impact.
            </p>
            <p>
              I am at my best when I am able to combine my research, creative,
              and technical skills. I especially love to work on projects that
              improve usability and accessibility.
            </p>
            </div>
            {/* to do: redesign to look more like this: https://mattfarley.ca/ */}
            <div className="column bio-container">
              <code>
                <h2 id="skills">Tools and Technologies</h2>
              </code>
              <p>Some of my favorite things to build with.</p>
              <div className="techologies row">
                {technologies.map((tech, i) => {
                  return (
                    <li key={i} className="value-item">
                      {tech}
                    </li>
                  );
                })}
              </div>
            </div>

            {/* <div className="column bio-container" id="portfolio">
              <Portfolio />
            </div> */}
          
        </div>
      </div>
    </div>
  );
}
export default HeroHeader;
