import React from 'react';
import HeroHeader from '../Home/Main/Main';

function Root ( ) {
    return(
<div>
        <HeroHeader/>
  
</div>
    )

}
export default Root;