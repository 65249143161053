import React from "react";
import moment from "moment";
function PhotoItem(props){
    return(
        <div key={props.keyRef} className="column-lg-5 photo-item"  >
                             <img style={props.style} onClick={()=>props.enlargePhoto(props.photo)} className="thumbnail" src={props.photo.fields.photo.fields.file.url} alt={props.photo.fields.photo.fields.description}/>
                       
                        <div className="metadata">
                          <div  className="metadata-column">
                          <p className="caption">{props.photo.fields.title} </p>
                          {props.photo.fields.location && <p className="italics"> {props.photo.fields.location}</p>}
                            {props.photo.fields.date && <p>{moment(props.photo.fields.date).format('MMMM Do, YYYY')}</p>}
                          {props.photo.fields.camera && <p>{props.photo.fields.camera}</p>}</div>
                          <div className="metadata-column">   
                          {props.photo.fields.focalLength && <p><span>focal length:</span> {props.photo.fields.focalLength}</p>}
                          {props.photo.fields.shutterSpeed && <p><span>shutter speed:</span> {props.photo.fields.shutterSpeed}</p>}
                          {props.photo.fields.aperture && <p><span>aperture:</span> {props.photo.fields.aperture}</p>}
                          {props.photo.fields.iso && <p><span>iso:</span> {props.photo.fields.iso}</p>}</div>
                     
                        </div>
                        </div>
    )
}
export default PhotoItem;