// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  a { 
    color: ${({ theme }) => theme.link};
    text-decoration: none;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: "Atkinson Hyperlegible", sans-serif;
    line-height: 1.2;
    transition: all 0.25s linear;
    font-size: 20px;
    scroll-behavior: smooth;
  }
  .align-center {
    align-items: center;
  }
  .between {
    justify-content: space-between;
  }

  .button {
    background-color: ${({ theme }) => theme.secondary};
    border: 0;
    box-shadow: 2px 3px ${({ theme }) => theme.toggleBorder};
    padding: 1rem;
    font-size: 1em;
    cursor: pointer;
  }
  .button:hover {
    box-shadow: 2px 3px ${({ theme }) => theme.containerText};
    transform: translateY(2px);
  }
  .breathing-room {
    margin-top: 2vh;
  }
  .center {
    justify-content: center;
  }
  .text-center {
    align-text: center
  }
  #code-editor {
    border: 10px solid ${({ theme }) => theme.toggleBorder};
    border-bottom: 10px solid ${({ theme }) => theme.secondary};
  }
  .color-bar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    height: 1.5em;
    background: ${({ theme }) => theme.containerHighlight};
  }
  .column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .contact-icon {
    margin: .3em;
    padding: 5px;
  }
  .end {
    justify-content: flex-end;
  }
  #experience > .row, #education > .row{
    border: 1px solid ${({theme}) =>theme.text};
    background-color: ${({theme}) =>theme.background_v2};
    border-radius: 5px;
  }
  #experience > .row:nth-child(2n), #education > .row:nth-child(2n){
    background-color: ${({ theme }) => theme.background_v1};
   
  }
  .evenly {
    justify-content: space-evenly;
  }
.head {
  margin-left: 5vw;
  transform: translateY(-4rem);
  
}
.head:hover {
  animation: bounce 1s infinite; 
}

.hero-header {
  min-height: 60vh;
  background-color: ${({ theme }) => theme.background_v1};
  box-shadow: 2px 3px ${({ theme }) => theme.body};
  padding-bottom: 1vh;
}
footer {
  background-color: #282c34;
  color: ${({theme}) => theme.body};
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 5vh;
}
.large {
  font-size: 2.5em;
}
.link {
  color: ${({ theme }) => theme.link};
  margin-bottom: 2em;
}
.link:hover {
 background-color: ${({ theme }) => theme.link};
 color: ${({ theme }) => theme.body};
 padding: .5em;
 border-radius: 4px;
 box-shadow: 2px 3px ${({ theme }) => theme.shadow};
 transition: .2s ease-in;
}
.loose-page {
padding-top: 10vh;
  padding-bottom: 25vh
}
nav > a {
  padding: .5em;
}
.no-wrap {
  flex-wrap: nowrap;
}
.number {
  background-color: ${({ theme }) => theme.highlight};
  text-align: center;
}
  .project-billboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    
  }
  .project-billboard * h2 {
    font-size: 2.5em;
  }
  .project-container {
    background-color: ${({ theme }) => theme.containerBody};
    color: ${({ theme }) => theme.containerText};
    margin: 10px;
    text-align: left;
  }
  .project-section, .video-404 { 
    font-size: 1.2em;
    text-align: left;
    padding: .5em;
    margin: .5em;
  }
  #project-container {
    border: 1px solid black;
    border-radius: 4px;
    margin-top: 5vh;
    background-color: ${({ theme }) => theme.background_v1};
  }
  .skill, .value-item {
    margin: .25em;
    border-radius: 10px;
    padding: 1em;
    background-color: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.body};
    list-style-type: none !important;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  section {
    padding: 5vh 0;
  }
  .section {
    margin-bottom: 10vh;
  }
  ::selection {
    color: ${({ theme }) => theme.body};
    background: ${({ theme }) => theme.selection};
  }
  .small-image {
    height: 100px;
  }
  // Scrollbar styles
  ::-webkit-scrollbar {
    height: 1rem;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.containerHighlightText};
    border-radius: 0.25rem;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.containerHighlightText};
    border-radius: 0.25rem;
  }
  .tag-container { 
    padding: 1em;
    margin-bottom: 2em;
  }
  .text-center {
    text-align: center;
  }
  .toggle-div {
    display: flex; 
    flex-diretion: row;
    justify-content: flex-end;
    align-items: center; 
    background-color:${({ theme }) => theme.background_v1};
    padding: 1em;
  }
 
  .wrapper {
    max-width: 80rem;
    padding: 0 1.3rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  /* For mobile: */
[class*="column-"] {
    width: 100%;
  }

  @media only screen and (min-width: 600px) {
    /* For tablets: */
    .column-md-1 {width: 8.33%;}
    .column-md-2 {width: 16.66%;}
    .column-md-3 {width: 25%;}
    .column-md-4 {width: 33.33%;}
    .column-md-5 {width: 41.66%;}
    .column-md-6 {width: 50%;}
    .column-md-7 {width: 58.33%;}
    .column-md-8 {width: 66.66%;}
    .column-md-9 {width: 75%;}
    .column-md-10 {width: 83.33%;}
    .column-md-11 {width: 91.66%;}
    .column-md-12 {width: 100%;}
    #scrolly {
      max-width:70rem;
      padding: 3em !important;
      margin-bottom: 15vh;
    
  }
  }
  @media only screen and (min-width: 900px) {
    /* For desktop: */
    .column-lg-1 {width: 8.33%;}
    .column-lg-2 {width: 16.66%;}
    .column-lg-3 {width: 25%;}
    .column-lg-4 {width: 33.33%;}
    .column-lg-5 {width: 41.66%;}
    .column-lg-6 {width: 50%;}
    .column-lg-7 {width: 58.33%;}
    .column-lg-8 {width: 66.66%;}
    .column-lg-9 {width: 75%;}
    .column-lg-10 {width: 83.33%;}
    .column-lg-11 {width: 91.66%;}
    .column-lg-12 {width: 100%;}
    #scrolly {
      max-width:70rem;
      padding: 3em !important;
      margin-bottom: 15vh;
    
  }
  }
  @media only screen and (max-width: 900px) {
    .wrapper {
      max-width: 95rem;
      padding: 0 1rem;
      margin: 0;
      position: inline-block;
    }
    .fullscreen{
      display: none;
    }
    .about-div {
      margin-top: 20vh;
    }
    #project-container {
      border: 0px solid black;
      border-radius: 0px;
      margin-top: 0;
      background-color: ${({ theme }) => theme.background_v1};
    }
  }
@keyframes bounce {
  0% {
    transform: translateY(-4rem);
  }
  50% {
    transform: translateY(-3rem);
  }
  `;
