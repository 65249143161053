import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import Lars from "../Main/avataaars.svg"
import PhotoItem from "./PhotoItem";

function Photography() {
  const [isOpen, setOpen] = useState(false);
  const [active, setActive] = useState({})
  const [photos, setPhotos] = useState([]);
  const [cursorStyle, setCursorStyle] = useState('zoom-in');
  const [tags, setTags] = useState([])
  const [showFiltered, setShowFiltered] = useState(false)
  const [filtered, setFiltered] = useState([]);
  const [currentTag, setCurrentTag] = useState('')
  //to do later, filter by tags
  // const [tags, setTags] = useState([])

  function enlargePhoto(photo) {
    const width = window.innerWidth;
    console.log(width)
    if (width > 700) {
      window.scrollTo({ top: ((document.documentElement.scrollHeight - window.innerHeight) / 2) - 150, behavior: "smooth" })
      setActive(photo);
      setOpen(true)
    }
  }
  function onClose() {
    setOpen(false)
  }

  function filterItems(tag) {
    let currentPhotos = photos;
    let filtered = []
    for (let i = 0; i < currentPhotos.length; i++) {
      let tags = currentPhotos[i].fields.tags;
      for (let j = 0; j < tags.length; j++) {
        if (tags[j].fields.tagName === tag) {
          filtered.push(currentPhotos[i])
        } else {
          console.log('no match')
        }
      }
    }
    setFiltered(filtered)
    setCurrentTag(tag)
    setShowFiltered(true)

  }
  useEffect(() => {
    const width = window.innerWidth;
    if (width < 700) {
      setCursorStyle("auto")
    }
    const client = createClient({
      space: "5t0chk9jmmek",
      accessToken: "RAQIPAiMCMB4alHZP1UMj4f3zYyD1nfvtk8829iSjLc",
    });
    client
      .getEntries({ content_type: "lzmsitePhotos" })
      .then((response) => {
        let data = response.items;
        data.sort(function (a, b) {
          let c = new Date(a.fields.date);
          let d = new Date(b.fields.date);
          return d - c;
        })
        setPhotos(data);
        let tags = data.map((data) => data.fields.tags);
        let tagsToAdd = [];
        for (let i = 0; i < tags.length; i++) {
          for (let j = 0; j < tags[i].length; j++) {
            let index = tagsToAdd.indexOf(tags[i][j].fields.tagName);
            if (index === -1) {
              tagsToAdd.push(tags[i][j].fields.tagName)
            }
          }
        }
        setTags(tagsToAdd)
      })
      .catch(console.error);
  }, []);
  return (
    <div className="wrapper billboard">
      <div className="column-lg-12 row center">
        <div className="column column-md-6 column-lg-5">
          <img
            src={Lars}
            className="column-lg-12 hero-image"
            alt="Lars headshot"
          />
        </div>
        <div className="column column-lg-5 column-md-12 bio-container center billboard">
          <code>tl;dr</code>
          <h1>
            Lars Mackenzie does {" "}
            <span className="highlight">photography</span>, too.{" "}
          </h1>
          <nav><a href="/#about">about</a><a href="/#skills">skills</a><a href="/#contact">contact</a><a href="/photography">photography</a></nav>
        </div>
      </div>

      {!showFiltered ? (<div className="tags-container">
        {tags.map((tag, i) => {
          return (<span onClick={() => filterItems(tag)} className="tag">{tag}</span>)
        })}
        <span onClick={() => setShowFiltered(false)} className="clear-tag">reset</span>
      </div>): (<div className="tags-container"><span className="tag no-highlight">{currentTag}</span><span onClick={() => setShowFiltered(false)} className="clear-tag">reset</span></div>)}
      <dialog open={isOpen} onClose={onClose}>
        <div className="flex-end"><button onClick={onClose} className="dialog-button">{`{ x }`}</button></div>
        {isOpen && <img className="full-size-img" src={active.fields.photo.fields.file.url} alt={active.fields.photo.fields.description} />}

      </dialog>
      {isOpen ? (<div className="photo-container disabled" >

        {showFiltered && <>{filtered.map((photo, i) => {
          return (<PhotoItem keyRef={i} style={{ cursor: cursorStyle }} enlargePhoto={enlargePhoto} photo={photo} />)
            ;
        })}</>}
        {!showFiltered && <>{photos.map((photo, j) => {
          return (<PhotoItem keyRef={j}  style={{ cursor: cursorStyle }} enlargePhoto={enlargePhoto} photo={photo} />)
            ;
        })}</>}
      </div>) : (<div className="photo-container" >
        {!showFiltered && <>{photos.map((photo, k) => {
          return (<PhotoItem  style={{ cursor: cursorStyle }} keyRef={k} enlargePhoto={enlargePhoto} photo={photo} />)
            ;
        })}</>}
        {showFiltered && <>{filtered.map((photo, l) => {
          return (<PhotoItem  style={{ cursor: cursorStyle }} keyRef={l} enlargePhoto={enlargePhoto} photo={photo} />)
            ;
        })}</>}
      </div>)}
    </div>
  )
}
export default Photography;