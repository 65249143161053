import React from "react";
import "./404.css";
import Lars from "../Home/Main/avataaars.svg";
function fourOhFour() {
  return (
    <div className="page">
      <div className="row">
        <a href="/">
          <img src={Lars} height="150px" alt="Lars' head" />
        </a>
      </div>
      <section className="section wrapper row center">
      
      <div><h2>nothing here</h2>
      <p> <a href="/">try going back home</a>.</p></div>
     
      </section>
 
    </div>
  );
}
export default fourOhFour;
