import React from "react";
import "./contact.css";
import { useTheme } from "styled-components";

function Contact() {
  // check to see what theme we have so that we can pull in the right icons
  // to do: create dark versions of all of the svgs and add the option for these in the icons array
  
  let year = new Date();
  year = year.getFullYear();

  const theme = useTheme();
  let light;
  if (theme.name === "light") {
    light = true;
  } else if (theme.name === "dark") {
    light = false;
  }
  let icons = [

    {
      imageDark:
        "https://lars-prof-site.s3.us-east-2.amazonaws.com/linkedin.d7874b5e.svg",
      url: "https://www.linkedin.com/in/lars-z-mackenzie/",
      imageLight:
        "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/linkedin.light.svg",
      alt: "linkedin",
    },

    {
      imageDark:
        "https://lars-prof-site.s3.us-east-2.amazonaws.com/envelope.a3ccee01.svg",
      imageLight:
        "https://lars-prof-site.s3.us-east-2.amazonaws.com/portfolio/envelope.light.svg",
      url: "mailto:larsmackenzie@gmail.com?subject=Hi%20Lars!",
      alt: "email",
    },
    {
      imageDark: "https://lars-prof-site.s3.us-east-2.amazonaws.com/github-logo.71e8a8d0.svg", imageLight: "https://lars-prof-site.s3.us-east-2.amazonaws.com/github-logo.71e8a8d0.svg", url: "https://github.com/larsz-o/", alt: ""
    }
  ];
  return (
    <footer className="row" id="contact">
      <div className="contact-button section">
        <h3>get in touch!</h3>
        {light !== undefined && light ? (
          <div className="row column-lg-12 column-sm-10">
            {icons.map((icon, i) => {
              return (
                <a href={icon.url} key={i} target="_blank" rel="noopener noreferrer">
                  <img
                    src={icon.imageDark}
                    alt={icon.alt}
                    className="contact-icon"
                  />
                </a>
              );
            })}
          </div>
        ) : (
          <div className="row column-lg-12">
            {icons.map((icon, i) => {
              return (
                <a href={icon.url} key={i} target="_blank" rel="noopener noreferrer">
                  <img
                    src={icon.imageLight}
                    alt={icon.alt}
                    className="contact-icon"
                  />
                </a>
              );
            })}
          </div>
        )}
      </div>
      <div className="row center padding-sm column-lg-12">
        <p>Unless otherwise stated, all rights reserved. &copy; {year}</p>
      </div>
    </footer>
  );
}
export default Contact;
